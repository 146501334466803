// src/components/Team.jsx
import React from 'react';
import styled from 'styled-components';
import TeamCarousel from './TeamCarousel';

const TeamContainer = styled.div`
  padding: 6rem 2rem 2rem;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  line-height: 1.6;

  @media (max-width: 768px) {
    padding: 5rem 1rem 2rem;
  }
`;

const SectionRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2.5rem;
  flex-direction: row;
  padding-left: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 1rem;
    padding-left: 0;
    margin-top: 2rem;
  }
`;

const SectionTitle = styled.h3`
  font-size: 0.8rem;
  color: #e0e0e0;
  min-width: 150px;
  margin-right: 1rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin-right: 0;
    font-size: 0.7rem;
  }
`;

// Desktop-Ansicht ohne <br />
const SectionContentDesktop = styled.div`
  font-size: 0.8rem;
  text-align: justify;
  text-transform: uppercase;
  line-height: 1.5;

  @media (max-width: 768px) {
    display: none; /* Verstecke diesen Block in der mobilen Ansicht */
  }
`;

// Mobile-Ansicht mit <br /> für Umbrüche
const SectionContentMobile = styled.div`
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 1.5;
  display: none; /* Standardmäßig ausblenden */

  @media (max-width: 768px) {
    display: block; /* Nur in der mobilen Ansicht anzeigen */
    font-size: 0.65rem;
    text-align: left;
  }
`;

// Spezialcontainer für den zweiten Abschnitt, ohne Einschränkung für Mobilgeräte
const SpecialThanksContainer = styled(SectionRow)`
  margin-top: 2.5rem; /* Erhöht den Abstand nach oben */
`;

const SpecialThanksContent = styled.div`
  font-size: 0.8rem;
  text-align: justify;
  text-transform: uppercase;
  line-height: 1.5;
  color: #e0e0e0;

  p {
    margin-bottom: 1rem; /* Abstand zwischen Absätzen */
  }

  @media (max-width: 768px) {
    font-size: 0.65rem; /* Kleinere Schrift für mobile Ansicht */
    text-align: left;
  }
`;

const Team = () => (
  <TeamContainer>
    <TeamCarousel />
    <SectionRow>
      <SectionTitle>CREDITS</SectionTitle>

      {/* Desktop-Ansicht */}
      <SectionContentDesktop>
        <p>Director: Martina Chamrad</p>
        <p>Idea/ Producer: Melda Ahmetoglu</p>
        <p>Creative Producer: Franziska Heinemann</p>
        <p>Director of Photography: Rafael Winkler</p>
        <p>Assistant Camera/ Gaffer: Marco Riedl</p>
        <p>Production: Boxfish</p>
        <br></br>
        <p>Editor: Marco Riedl</p>
        <p>Color Grading: Franziska Heinemann</p>
        <p>Music: Achim Gössl</p>
        <p>Sound Design: Nikolaus Böll</p>
        <p>Translation: Mihriban Ada</p>
        <br></br>
        <p>Cast: Gülzire Taschmamat, Ipar Can, Nurahmet Tursun</p>
        <br></br>
        <p>Photographer: Marcel Gollin</p>
        <p>Behind the Scenes: Alexander Draheim</p>
      </SectionContentDesktop>

      {/* Mobile-Ansicht */}
      <SectionContentMobile>
        <p>Director:<br />Martina Chamrad</p>
        <p>Idea/ Producer:<br />Melda Ahmetoglu</p>
        <p>Creative Producer:<br />Franziska Heinemann</p>
        <p>Director of Photography:<br />Rafael Winkler</p>
        <p>Assistant Camera/ Gaffer:<br />Marco Riedl</p>
        <p>Production:<br />Boxfish</p>
        <br></br>
        <p>Editor:<br />Marco Riedl</p>
        <p>Color Grading:<br /> Franziska Heinemann</p>
        <p>Music:<br />Achim Gössl</p>
        <p>Sound Design:<br />Nikolaus Böll</p>
        <p>Translation:<br />Mihriban Ada</p>
        <br></br>
        <p>Cast:<br />Gülzire Taschmamat<br /> Ipar Can <br /> Nurahmet Tursun</p>
        <br></br>
        <p>Photographer:<br />Marcel Gollin</p>
        <p>Behind the Scenes:<br />Alexander Draheim</p>
      </SectionContentMobile>
    </SectionRow>
    
    <SpecialThanksContainer>
      <SectionTitle>SPECIAL THANKS</SectionTitle>
      <SpecialThanksContent>
        <p>A heartfelt thank you goes to the entire team who volunteered their time and efforts on this project, making the realization of the film possible and bringing such an important issue to light.</p>
        <p>We would also like to thank Prof. Johanna Diehl and Prof. Henning Rogge Pott from the Technical University of Applied Sciences Würzburg-Schweinfurt for their theoretical and practical guidance during Melda Ahmetoglu's bachelor’s thesis, in which this short documentary film was initiated.</p>
        <p>Last but not least, we want to extend our deepest gratitude to the Uyghur community in Munich, who generously offered us insights into their lives. Many of them, filled with passion, pain, and hope, work tirelessly to keep their diverse and beautiful culture alive in exile—one that is forbidden in their homeland and faces the threat of extinction.</p>
        <p>We sincerely hope for their swift reunion with their families in East Turkestan and dream of a future where all Uyghurs can live in freedom—both physically and in their hearts.</p>
      </SpecialThanksContent>
    </SpecialThanksContainer>
  </TeamContainer>
);

export default Team;
