// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Arial', sans-serif;
    color: #e0e0e0;
    background-color: #1b1b1f;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  a {
    color: #e94560;
    text-decoration: none;
  }

  /* Responsive Anpassungen für kleinere Bildschirme */
  @media (max-width: 768px) {
    body {
      font-size: 0.9rem;
    }
  }
`;
