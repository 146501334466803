import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import MeldaHome from './components/MeldaHome';
import HeroSection from './components/HeroSection';
import Intro from './components/Intro';
import About from './components/About';
import Team from './components/Team';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import { GlobalStyles } from './styles/GlobalStyles';

function App() {
  const [showHero, setShowHero] = useState(false);

  return (
    <Router>
      <GlobalStyles />
      <Navbar setShowHero={setShowHero} />
      <Routes>
        {/* Startseite */}
        <Route
          path="/"
          element={
            showHero ? <HeroSection autoplay /> : <Intro onIntroEnd={() => setShowHero(true)} />
          }
        />
        {/* Route /againstthesilence führt auch zur Startseite */}
        <Route path="/againstthesilence" element={<Navigate to="/" replace />} />
        {/* Weitere Routen */}
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
