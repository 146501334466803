// src/components/HeroSection.jsx
import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #e0e0e0;
  padding: 4rem 1rem;
  min-height: 100vh;
  gap: 1.5rem;
  background-color: #1b1b1f;
  
  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
  }
`;

// Der HeroText ist hier auskommentiert und bleibt im Code erhalten, falls du ihn später wieder hinzufügen möchtest.
// const HeroText = styled.h1`
//   font-size: 3rem;
//   color: #e0e0e0;
//   margin-bottom: 1rem;
//   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

//   @media (max-width: 768px) {
//     font-size: 2rem;
//   }
// `;

const VideoWrapper = styled.div`
  width: 80%;
  max-width: 800px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const HeroSection = ({ autoplay }) => {
  return (
    <HeroContainer>
      {/* <HeroText>Against The Silence</HeroText> */}
      <VideoWrapper>
      <iframe
        src="https://www.youtube.com/embed/JvyUMLmTOUg?rel=0&enablejsapi=1&modestbranding=1&autoplay=0"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loading="lazy"
      ></iframe>
      </VideoWrapper>
    </HeroContainer>
  );
};

export default HeroSection;
