// src/components/TeamCarousel.jsx
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from 'styled-components';
import alex from '../assets/teamphotos/alex.jpg';
import franzi from '../assets/teamphotos/franzi.jpg';
import rafa from '../assets/teamphotos/rafa.jpg';
import marco from '../assets/teamphotos/marco.jpg';
import marco2 from '../assets/teamphotos/marco2.jpg';
import martina from '../assets/teamphotos/martina.jpg';
import team from '../assets/teamphotos/team.jpg';

const CarouselContainer = styled.div`
  flex: 1;
  max-width: 800px;
  margin: 0 auto 1rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const TeamCarousel = () => {
  const images = [team, martina, alex, franzi, rafa, marco, marco2];

  return (
    <CarouselContainer>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={3000}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Team member ${index + 1}`} />
          </div>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default TeamCarousel;
