import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PrivacyContainer = styled.div`
  padding: 6rem 2rem;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  background-color: #1b1b1f;
  line-height: 1.6;

  h2, h3 {
    color: #e0e0e0;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    text-align: justify;
  }

  ul {
    margin-left: 1.5rem;
    list-style-type: disc;
  }
`;

// Styling for the highlighted contact link
const HighlightedLink = styled(Link)`
  color: #1e90ff;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const PrivacyPolicy = () => (
  <PrivacyContainer>
    <h2>Privacy Policy</h2>
    
    <h3>1. General Information</h3>
    <p>We take the protection of your personal data very seriously and treat your personal data confidentially and in accordance with statutory data protection regulations.</p>

    <h3>2. Data Collection on Our Website</h3>
    <p>Data collection on this website is carried out by the website operator. Feel free to <HighlightedLink to="/contact">contact</HighlightedLink> us for more information.</p>

    <h3>3. Your Rights Regarding Data</h3>
    <p>You have the right to obtain information about the origin, recipient, and purpose of your stored personal data free of charge. You also have a right to request the correction or deletion of this data.</p>

    <h3>4. Data Collection on Our Website</h3>
    <p>We collect and process personal data (e.g., name, address, email address) only with your express permission or if permitted by law. This data will not be disclosed to third parties without your express consent.</p>

    <h3>5. Data Processing in Connection with Contacting Us</h3>
    <p>If you contact us via our contact form, the data provided by you will be processed for the purpose of handling your request. This data is stored for follow-up questions and will not be shared without your consent.</p>

    <h3>6. Use of Cookies</h3>
    <p>This website uses cookies to enhance user experience. Some cookies are stored on your device until you delete them. They allow us to recognize your browser on your next visit.</p>

    <h3>7. Analysis Tools and Advertising</h3>
    <p>We use analytics tools to track website usage. This data is typically collected anonymously but can sometimes be linked to your personal data.</p>

    <h3>8. Plugins and Tools</h3>
    <p>Our website may include third-party tools and plugins (e.g., Google Maps) to provide certain features. Data may be shared with these providers if you choose to interact with these plugins.</p>

    <h3>Contact</h3>
    <p>If you have further questions about our privacy policy, please feel free to reach us using the <HighlightedLink to="/contact">contact</HighlightedLink> form provided on our website.</p>
  </PrivacyContainer>
);

export default PrivacyPolicy;
