// src/components/Intro.jsx
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Hauptbild from '../assets/gds_gulzire.jpg';
import Award1 from '../assets/award1.png';
import Award2 from '../assets/award2.png';
import Award3 from '../assets/award3.png';
import Award4 from '../assets/award4.png';
import Award5 from '../assets/award5.png';

const fadeInEffect = keyframes`
  0% { opacity: 0.5; filter: blur(10px); }
  100% { opacity: 1; filter: blur(0); }
`;

const IntroContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url(${Hauptbild}) no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: ${fadeInEffect} 3s ease-in-out forwards;

  @media (max-width: 768px) {
    background-position: 78% center;
  }
`;

const AwardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: nowrap;
  margin-top: 1.5rem;

  img {
    width: 120px;
    height: auto;
  }

  @media (max-width: 768px) {
    gap: 0.5rem;
    img {
      width: 70px;
    }
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); opacity: 0.7; }
  50% { transform: scale(1.2); opacity: 1; }
  100% { transform: scale(1); opacity: 0.7; }
`;

const PlayIconDesktop = styled.div`
  position: absolute;
  color: #ffffff;
  pointer-events: none;
  animation: ${pulse} 1.5s infinite;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    display: none; /* Hide the animated play icon on mobile */
  }
`;

const PlayIconMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 65%;
    color: #ffffff;
    transform: translate(-50%, -50%);
    animation: ${pulse} 1.5s infinite;

    svg {
      width: 26px; /* Smaller size for mobile */
      height: 26px;
    }
  }
`;

const Intro = ({ onIntroEnd }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <IntroContainer onClick={onIntroEnd} onMouseMove={handleMouseMove}>
      <AwardsContainer>
        <img src={Award1} alt="Award 1" />
        <img src={Award2} alt="Award 2" />
        <img src={Award3} alt="Award 3" />
        <img src={Award4} alt="Award 4" />
        <img src={Award5} alt="Award 5" />
      </AwardsContainer>
      <PlayIconDesktop
        style={{
          top: `${mousePosition.y + 15}px`,
          left: `${mousePosition.x + 15}px`,
        }}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
          <polygon points="5,3 19,12 5,21" />
        </svg>
      </PlayIconDesktop>
      <PlayIconMobile>
        <svg width="36" height="36" viewBox="0 0 24 24" fill="white">
          <polygon points="5,3 19,12 5,21" />
        </svg>
      </PlayIconMobile>
    </IntroContainer>
  );
};

export default Intro;
