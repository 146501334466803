// src/components/Navbar.jsx
import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

const NavbarContainer = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #1b1b1f;
  position: fixed;
  top: 0;
  z-index: 100;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
`;

const Logo = styled.div`
  color: #e0e0e0;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: bold;  //um Fett zu machen


  @media (max-width: 768px) {
    font-size: 0.8rem;    //Handy-Ansicht Against the Silence 
    margin-left: 0;
  }
`;

const Menu = styled.ul`
  display: flex;
  gap: 1rem; /* Default gap for desktop */
  list-style: none;

  @media (max-width: 768px) {
    gap: 0.5rem; /* Reduced gap for mobile */
  }
`;


const MenuItem = styled(Link)`
  color: #e0e0e0;
  text-decoration: none;
  font-size: 0.9rem; /* Default font size for desktop */
  cursor: pointer;
  position: relative;

  &:hover {
    color: #e0e0e0;
  }

  &:hover::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px solid #e0e0e0;
    position: absolute;
    bottom: 2; /* Stick to the baseline */
    transform: translateY(4px); /* Adjust spacing as needed -> für underline*/
    left: 0;
  }

  /* Mobile-specific styling */
  @media (max-width: 768px) {
    font-size: 0.6rem; /* Adjust font size for mobile */  Home About Team
  }
`;


const Navbar = ({ setShowHero }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    setShowHero(false);
    navigate('/'); //hier ändern auf /intro später
  };

  return (
    <NavbarContainer>
      <Logo onClick={handleLogoClick}>AGAINST THE SILENCE</Logo>
      <Menu>
        <MenuItem to="/">HOME</MenuItem>
        <MenuItem to="/about">ABOUT</MenuItem>
        <MenuItem to="/team">TEAM</MenuItem>
        <MenuItem to="/contact">CONTACT</MenuItem>
      </Menu>
    </NavbarContainer>
  );
};

export default Navbar;
