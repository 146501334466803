// src/components/Contact.jsx
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;
  max-width: 600px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  line-height: 1.6;
  background-color: #1b1b1f;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: #e0e0e0;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #262626;
  color: #ffffff;
  font-size: 0.9rem;
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: #262626;
  color: #ffffff;
  font-size: 0.9rem;
  resize: none;
  height: 120px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const PrivacyLink = styled.a`
  color: #1e90ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  background-color: ${({ disabled }) => (disabled ? '#555' : '#1e90ff')};
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#555' : '#1e90ff')};
  }
`;

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
    privacy: false,
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!formData.privacy) {
      toast.error('Please agree to the privacy policy before submitting.');
      return;
    }

    if (!isEmailValid(formData.user_email)) {
      toast.error('Please provide a valid email address.');
      return;
    }

    setIsSending(true);
    emailjs
      .sendForm('service_17jf2hm', 'template_fcg1o1b', form.current, 'JptPcg6mZLRnOYe4f')
      .then(
        () => {
          toast.success('Email successfully sent!');
          setFormData({ user_name: '', user_email: '', message: '', privacy: false });
        },
        (error) => {
          toast.error('Failed to send message: ' + error.text);
        }
      )
      .finally(() => setIsSending(false));
  };

  return (
    <ContactContainer>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <Title>CONTACT US</Title>
      <Form ref={form} onSubmit={sendEmail}>
        <Label htmlFor="name">NAME</Label>
        <Input
          type="text"
          id="name"
          name="user_name"
          required
          value={formData.user_name}
          onChange={handleChange}
        />
        <Label htmlFor="email">EMAIL ADDRESS</Label>
        <Input
          type="email"
          id="email"
          name="user_email"
          required
          value={formData.user_email}
          onChange={handleChange}
        />
        <Label htmlFor="message">MESSAGE</Label>
        <TextArea
          id="message"
          name="message"
          required
          value={formData.message}
          onChange={handleChange}
        />
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            id="privacy"
            name="privacy"
            checked={formData.privacy}
            onChange={handleChange}
            required
          />
          <Label htmlFor="privacy">
            I agree to the <PrivacyLink href="/privacy-policy" target="_blank">privacy policy</PrivacyLink>
          </Label>
        </CheckboxContainer>
        <Button type="submit" disabled={isSending || !formData.privacy || !formData.user_name || !formData.user_email || !formData.message}>
          {isSending ? 'Sending...' : 'SEND'}
        </Button>
      </Form>
    </ContactContainer>
  );
};

export default Contact;
