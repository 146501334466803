import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HauptbildDesktop from '../assets/gds_gulzire.jpg';
import HauptbildMobil from '../assets/Plakat-Melda.jpg';
import Award1 from '../assets/award1.png';
import Award2 from '../assets/award2.png';
import Award3 from '../assets/award3.png';
import Award4 from '../assets/award4.png';
import Award5 from '../assets/award5.png';
import DesktopVideo from '../assets/video/desktop.mov';
import MobileVideo from '../assets/video/mobil.mov';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  position: relative;
  padding: 20px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileVideoBackground = styled.video`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;

  @media (max-width: 768px) {
    display: block;
  }
`;

const TitleName = styled.h1`
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 28px; /* Kleinere Schriftgröße für Mobilgeräte */
    white-space: nowrap; /* Verhindert Zeilenumbruch */
  }
`;

const TitleFilme = styled.h2`
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 300;
  color: #1e90ff; /* Blaue Farbe für den Titel */
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  z-index: 1;
`;

const BackgroundImage = styled.div`
  width: 25%;
  max-width: 350px;
  height: 25%;
  background-image: url(${HauptbildDesktop});
  background-size: cover;
  background-position: right center; /* Bild mehr nach rechts verschieben */
  border-radius: 10px;
  position: relative;
  transition: transform 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:hover {
    transform: scale(1.05);
  }

  &:hover::before {
    content: "Against the Silence";
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-size: 24px;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
  }

  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 5px solid #1e90ff;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    width: 60%; /* Kleinere Größe der Karte für Mobilgeräte */
    max-width: 300px;
    height: 45%; /* Kleinere Höhe für Mobilgeräte */
    background-image: url(${HauptbildMobil});
    background-position: center;

    &:hover::before {
      content: none;
    }

    &::before {
      content: "Against the Silence";
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      color: white;
      font-size: 18px;
      font-weight: bold;
      background: rgba(0, 0, 0, 0.7);
      padding: 5px 10px;
      border-radius: 5px;
      text-align: center;
    }
  }
`;

const AwardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  position: absolute;
  top: 50%; /* Mittig für Desktop */
  transform: translateY(-50%); /* Zentriert für Desktop */
  z-index: 1;
  flex-wrap: nowrap; /* Verhindert Zeilenumbruch in allen Ansichten */

  img {
    width: 60px;
    height: auto;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    top: 60%; /* Awards leicht nach unten verschieben */
    transform: translateY(8%);
    gap: 0.2rem; /* Geringerer Abstand in der Mobilansicht */
    img {
      width: 35px; /* Kleinere Awards für Mobilgeräte */
    }
  }
`;

function MeldaHome() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/intro'); // Navigiert zur Intro-Seite
  };

  return (
    <Wrapper>
      {/* Desktop Video */}
      <VideoBackground autoPlay loop muted playsInline src={DesktopVideo} type="video/mp4" />

      {/* Mobile Video */}
      <MobileVideoBackground autoPlay loop muted playsInline src={MobileVideo} type="video/mp4" />

      <TitleName>MELDA AHMETOGLU</TitleName>
      <TitleFilme>Films</TitleFilme>
      <BackgroundImage onClick={handleClick}>
        <AwardsContainer>
          <img src={Award1} alt="Award 1" />
          <img src={Award2} alt="Award 2" />
          <img src={Award3} alt="Award 3" />
          <img src={Award4} alt="Award 4" />
          <img src={Award5} alt="Award 5" />
        </AwardsContainer>
      </BackgroundImage>
    </Wrapper>
  );
}

export default MeldaHome;
