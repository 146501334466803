// src/components/About.jsx
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Still1 from '../assets/Still_1.png';
import Still2 from '../assets/Still_2.png';
import Still3 from '../assets/Still_3.png';
import Still4 from '../assets/Still_4.png';
import Still5 from '../assets/Still_5.png';
import Still6 from '../assets/Still_6.png';
import Still7 from '../assets/Still_7.png';
import Still8 from '../assets/Still_8.png';
import Still9 from '../assets/Still_9.png';
import Still10 from '../assets/Still_10.png';
import Still11 from '../assets/Still_11.png';
import Still12 from '../assets/Still_12.png';
import BW1 from '../assets/black&white/BW_1.jpg';
import BW3 from '../assets/black&white/BW_3.jpg';
import BW4 from '../assets/black&white/BW_4.jpg';
import BW5 from '../assets/black&white/BW_5.jpg';
import BW6 from '../assets/black&white/BW_6.jpg';
import BW8 from '../assets/black&white/BW_8.jpg';
import BW9 from '../assets/black&white/BW_9.jpg';

const AboutContainer = styled.div`
  padding: 6rem 2rem 2rem;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  line-height: 1.6;

  @media (max-width: 768px) {
    padding: 5rem 1rem 2rem;
  }
`;

// Keyframe for simultaneous fade-in animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// ImageGrid with simultaneous fade-in effect
const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 3rem;
`;

const ImageItem = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 0;
  animation: ${fadeInUp} 2.0s ease forwards;
  animation-delay: 0.5s; /* Slight delay before all images appear */
`;

// Black-and-white images grid styling
const BWImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2.5rem;
`;

const BWImageItem = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  object-fit: cover;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  ${({ isVisible }) => isVisible && `
    opacity: 1;
    transform: translateY(0);
  `}
`;

const SectionRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  flex-direction: row;

  @media (max-width: 768px) {
    margin-top: ${({ isSecondSection }) => (isSecondSection ? '2rem' : '-1rem')};
  }
`;

const SectionTitle = styled.h3`
  font-size: 0.8rem;
  color: #e0e0e0;
  min-width: 150px;
  margin-right: 1rem;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const SectionContent = styled.p`
  font-size: 0.8rem;
  text-align: justify;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.65rem;
    text-align: left;
  }
`;

const About = () => {
  const images = [Still1, Still2, Still7, Still8, Still3, Still4, Still9, Still10, Still5, Still6, Still11, Still12];
  const bwImages = [BW4, BW3, BW1, BW5, BW9, BW6];
  
  const imageRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = "1";
            entry.target.style.transform = "translateY(0)";
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );
    
    imageRefs.current.forEach((img) => {
      if (img) observer.observe(img);
    });
    
    return () => observer.disconnect();
  }, []);

  return (
    <AboutContainer>
      <ImageGrid>
        {images.map((src, index) => (
          <ImageItem 
            key={index} 
            src={src} 
            alt={`Still ${index + 1}`} 
          />
        ))}
      </ImageGrid>

      <SectionRow>
        <SectionTitle>ABOUT</SectionTitle>
        <SectionContent>
          »Against the Silence« is a poignant short documentary that tells the gripping story of Gülzire 
          Tashmamat, a Uyghur living in exile. Gülzire's tireless struggle to remember her disappeared 
          sister symbolizes the silent tragedy and forced silence endured by the Uyghur community worldwide.
          <br /><br />
          This film provides an intimate portrait of a woman shaped by loss and hope. Through her eyes 
          and experiences, the film offers insight into the chaotic and magical creative process required 
          to keep an oppressed culture and identity alive in exile. Gülzire's story is a call for recognition 
          and support for a frequently overlooked community.
        </SectionContent>
      </SectionRow>

      <SectionRow isSecondSection>
        <SectionTitle>WHY »AGAINST THE SILENCE«?</SectionTitle>
        <SectionContent>
          In a world often dominated by the loudest voices, »Against the Silence« seeks out the unheard 
          stories. This film stretches the definition of creativity and delves deep into the moving and 
          complex reality of the Uyghur diaspora. It challenges viewers to listen and to see where others 
          turn away, breaking the silence that has prevailed for so many years.
        </SectionContent>
      </SectionRow>

      <BWImageGrid>
        {bwImages.map((src, index) => (
          <BWImageItem 
            key={index} 
            src={src} 
            alt={`Black and White ${index + 1}`} 
            ref={(el) => imageRefs.current[index] = el}
          />
        ))}
      </BWImageGrid>
    </AboutContainer>
  );
};

export default About;
